import React from 'react'
import Layout from '../components/layout'
import { Link, useStaticQuery, graphql } from 'gatsby'
import './blog.scss';
import Head from '../components/head'
const Blog = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulBlogPost ( sort: {fields: publishedDate, order: DESC } ){
          edges {
            node {
              title
              slug
              publishedDate(formatString: "MMMM Do, YYYY")
            }
          }
        }
      }
    `)

    return (
        <Layout>
        <Head title='Blog'/>
            <h1>Blog Page</h1>
            <ol className = "posts">
                {data.allContentfulBlogPost.edges.map((edge) => {
                    return (
                        <li className="post">
                           <Link to={`${edge.node.slug}`}> <h2>{edge.node.title}</h2></Link>
                            <p>{edge.node.publishedDate}</p>
                        </li>
                    )
                })}

            </ol>
        </Layout>
    )
}

export default Blog